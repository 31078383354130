// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-components-js": () => import("./../../../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-docs-v-1-2-0-js": () => import("./../../../src/pages/docs/v1.2.0.js" /* webpackChunkName: "component---src-pages-docs-v-1-2-0-js" */),
  "component---src-pages-docs-v-2-0-0-beta-10-js": () => import("./../../../src/pages/docs/v2.0.0-beta.10.js" /* webpackChunkName: "component---src-pages-docs-v-2-0-0-beta-10-js" */),
  "component---src-pages-docs-v-2-0-0-beta-11-js": () => import("./../../../src/pages/docs/v2.0.0-beta.11.js" /* webpackChunkName: "component---src-pages-docs-v-2-0-0-beta-11-js" */),
  "component---src-pages-docs-v-2-0-0-beta-9-js": () => import("./../../../src/pages/docs/v2.0.0-beta.9.js" /* webpackChunkName: "component---src-pages-docs-v-2-0-0-beta-9-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

